import React, { useState, useEffect, useRef } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useNavigate, useLocation } from "react-router-dom";

import HeaderPopUp from "./HeaderPopUp";
import PersonalInboxModal from "../chat/modal/PersonalInboxModal";
import { db } from "../../firebase";
import useFetchPersonalChatInbox from "../../hooks/chat/useFetchPersonalChatInbox";
import { useAuth } from "../../contexts/AuthContext";
import { getPlaceholderByGender } from "../../utils/utils";
import constants from "../../utils/constants";
import accessConstants from "../../utils/accessConstants";

const { PATH, DB_NAME } = constants;
const { ADMIN_TAB_ACCESS } = accessConstants;

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser } = useAuth();
  const email = get(currentUser, "email", "");
  const uid = get(currentUser, "uid", "");

  const headerPopupRef = useRef();
  const [page, setPage] = useState("");
  const [profile, setProfile] = useState("");
  const [isOpenHeaderPopUp, setIsOpenHeaderPopUp] = useState(false);
  const [isPersonalInboxOpen, setIsPersonalInboxOpen] = useState(false);
  const { unreadChatCount } = useFetchPersonalChatInbox();

  const handleImageError = (event) => {
    return (event.target.src = getPlaceholderByGender(
      get(profile, "gender", "")
    ));
  };

  const handleChatClick = () => {
    if (currentUser) {
      setIsPersonalInboxOpen(!isPersonalInboxOpen);
    } else {
      navigate(PATH.LOGIN);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const urlArray = pathname.split("/");
    setPage(urlArray[urlArray.length - 1]);
  }, [pathname]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpenHeaderPopUp &&
        headerPopupRef.current &&
        !headerPopupRef.current.contains(e.target)
      ) {
        setIsOpenHeaderPopUp(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpenHeaderPopUp]);

  const fetchUserProfile = async () => {
    await db
      .collection(DB_NAME.USERS)
      .doc(uid)
      .get()
      .then((doc) => {
        if (!isEmpty(doc.data())) {
          setProfile(doc.data());
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (uid) {
      setTimeout(() => {
        fetchUserProfile();
      }, 1000);
    }
  }, [uid]);

  return (
    <div className="header fixed z-10">
      <div className="float-right">
        {currentUser ? (
          <div className="mt-[6px] mr-2 " ref={headerPopupRef}>
            <span
              className="cursor-pointer header-profile-avatar"
              onClick={() => setIsOpenHeaderPopUp(!isOpenHeaderPopUp)}
            >
              <img
                src={
                  profile?.Img1
                    ? profile.Img1
                    : getPlaceholderByGender(profile?.gender)
                }
                alt="profile"
                onError={handleImageError}
              />
            </span>
            <div>
              <HeaderPopUp
                isOpenHeaderPopUp={isOpenHeaderPopUp}
                setIsOpenHeaderPopUp={setIsOpenHeaderPopUp}
                setIsPersonalInboxOpen={setIsPersonalInboxOpen}
                profile={profile}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center">
            <button
              className={`mt-4 me-4 px-2 py-1  ${
                page === "login" &&
                "outline outline-white rounded-lg hover:bg-white hover:text-black"
              }`}
              onClick={() => handleNavigate(PATH.LOGIN)}
            >
              Login
            </button>
            <button
              className={`mt-4 me-4 px-2 py-1 ${
                page === "signup" &&
                "outline outline-white rounded-lg hover:bg-white hover:text-black"
              }`}
              onClick={() => handleNavigate(PATH.SIGNUP)}
            >
              Sign Up
            </button>
          </div>
        )}
      </div>

      <div className="flex justify-center items-center ">
        <p
          className={`text-md cursor-pointer mt-4 ${
            page === "" && "underline underline-offset-4"
          }`}
          onClick={() => handleNavigate(PATH.HOME)}
        >
          Home
        </p>

        {/* <p
          className={`ml-7 text-md cursor-pointer mt-4 ${
            ["chat", "chatbox"].includes(page) && "underline underline-offset-4"
          }`}
          onClick={handleChatClick}
        >
          Chat {unreadChatCount ? `(${unreadChatCount})` : ""}
        </p> */}

        {ADMIN_TAB_ACCESS.includes(email) && (
          <p
            className={`ml-7 text-md cursor-pointer mt-4 ${
              ["admin"].includes(page) && "underline underline-offset-4"
            }`}
            onClick={() => handleNavigate(PATH.ADMIN)}
          >
            Admin
          </p>
        )}
      </div>

      {isPersonalInboxOpen && (
        <PersonalInboxModal
          isModalOpen={isPersonalInboxOpen}
          setIsPersonalInboxOpen={setIsPersonalInboxOpen}
          closeModal={() => setIsPersonalInboxOpen(false)}
        />
      )}
    </div>
  );
};

export default Header;
