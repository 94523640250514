import { useState, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { db } from "../../firebase";
import { AuthContext } from "../../contexts/AuthContext";
import constants from "../../utils/constants";

const { DB_NAME } = constants;

const useFetchIsYouBlockedReceiver = (receiverUid) => {
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser?.uid;

  const [isLoading, setIsLoading] = useState(false);
  const [isGotResponse, setIsGotResponse] = useState(false);
  const [isYouBlockedReceiver, setIsYouBlockedReceiver] = useState(false);

  const fetchIsReceiverBlockedForChat = async () => {
    setIsLoading(true);
    await db
      .collection(DB_NAME.BLOCKED_USERS)
      .doc(uid)
      .get()
      .then((doc) => {
        if (!isEmpty(doc.data())) {
          let data = doc.data();
          const isReceiverBlockedRes = data && data.blockedUser[receiverUid];

          if (isReceiverBlockedRes) {
            setIsYouBlockedReceiver(isReceiverBlockedRes);
            setIsGotResponse(true);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (uid) {
      fetchIsReceiverBlockedForChat();
    }
  }, [receiverUid]);

  return { isLoading, isYouBlockedReceiver, isGotResponse };
};

export default useFetchIsYouBlockedReceiver;
