import { useState, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { db } from "../../firebase";
import { AuthContext } from "../../contexts/AuthContext";
import constants from "../../utils/constants";

const { DB_NAME } = constants;

const useFetchUserProfile = (userId) => {
  const { currentUser } = useContext(AuthContext);
  const uid = userId ? userId : currentUser?.uid;

  const [isLoading, setIsLoading] = useState(false);
  const [isGotResponse, setIsGotResponse] = useState(false);
  const [response, setResponse] = useState(null);

  const fetchUserProfile = async () => {
    setIsLoading(true);
    await db
      .collection(DB_NAME.USERS)
      .doc(uid)
      .get()
      .then((doc) => {
        if (!isEmpty(doc.data())) {
          setResponse(doc.data());
        }
        setIsGotResponse(true);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (uid) {
      fetchUserProfile();
    }
  }, [uid]);

  return { isLoading, isGotResponse, response };
};

export default useFetchUserProfile;
