import firebase from "firebase/compat/app";
import getStripe from "../initializeStripe";
import constants from "../stripeConstants";

const {
  TEST_PRICE_ID,
  PROD_PRICE_ID,
  STRIPE_SUCCESS_URL,
  STRIPE_CANCEL_URL,
  LOADING_TIMEOUT,
} = constants;
const priceId = PROD_PRICE_ID; // TODO: Need to update price Id based on Test/Prod env

// Monthly VIP Plan
export async function monthlyVIPCS(uid, setIsloading, successUrl, cancelUrl) {
  const firestore = firebase.firestore();
  const updatedSuccessUrl = successUrl ? successUrl : STRIPE_SUCCESS_URL.HOME;
  const updatedCancelUrl = cancelUrl ? cancelUrl : STRIPE_CANCEL_URL.HOME;

  setIsloading(true);
  const checkoutSessionRef = await firestore
    .collection("users-stripe")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: priceId.MONTHLY_VIP,
      success_url: updatedSuccessUrl,
      cancel_url: updatedCancelUrl,
      allow_promotion_codes: true,
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();

    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, LOADING_TIMEOUT);
    }
  });
}

// Quartly VIP Plan
export async function quartlyVIPCS(uid, setIsloading, successUrl, cancelUrl) {
  const firestore = firebase.firestore();
  const updatedSuccessUrl = successUrl ? successUrl : STRIPE_SUCCESS_URL.HOME;
  const updatedCancelUrl = cancelUrl ? cancelUrl : STRIPE_CANCEL_URL.HOME;

  setIsloading(true);
  const checkoutSessionRef = await firestore
    .collection("users-stripe")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: priceId.QUARTERLY_VIP,
      success_url: updatedSuccessUrl,
      cancel_url: updatedCancelUrl,
      allow_promotion_codes: true,
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();

    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, LOADING_TIMEOUT);
    }
  });
}

// Yearly VIP Plan
export async function yearlyVIPCS(uid, setIsloading, successUrl, cancelUrl) {
  const firestore = firebase.firestore();
  const updatedSuccessUrl = successUrl ? successUrl : STRIPE_SUCCESS_URL.HOME;
  const updatedCancelUrl = cancelUrl ? cancelUrl : STRIPE_CANCEL_URL.HOME;

  setIsloading(true);
  const checkoutSessionRef = await firestore
    .collection("users-stripe")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: priceId.YEARLY_VIP,
      success_url: updatedSuccessUrl,
      cancel_url: updatedCancelUrl,
      allow_promotion_codes: true,
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();

    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, LOADING_TIMEOUT);
    }
  });
}
