import get from "lodash/get";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import constants from "../../../utils/constants";

const { DB_NAME } = constants;

const useBlockUser = (
  receiverUid,
  setIsYouBlockedReceiverUser,
  setIsLoading,
  setIsOpenModal
) => {
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");

  const handleBlock = async () => {
    setIsOpenModal(false);
    setIsLoading(true);

    await db
      .collection(DB_NAME.BLOCKED_USERS)
      .doc(uid)
      .set(
        {
          blockedUser: {
            [receiverUid]: true,
          },
        },
        { merge: true }
      )
      .then(() => {
        // set(
        //   ref(
        //     realTimeDB,
        //     `${REALTIME_DB_NAME.PERSONAL_CHAT_INBOX}/` + `${uid}/` + receiverUid
        //   ),
        //   null
        // );
        setIsYouBlockedReceiverUser(true);
        setIsLoading(false);
        toast.success("User Blocked");
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Something went wrong. Please try again.");
      });

    await db
      .collection(DB_NAME.USERS)
      .doc(receiverUid)
      .set(
        {
          blockedCount: firebase.firestore.FieldValue.increment(1),
        },
        { merge: true }
      );

    setIsOpenModal(false);
  };

  return { handleBlock };
};

export default useBlockUser;
