import get from "lodash/get";
import { ref, set } from "firebase/database";
import { toast } from "react-toastify";
import { db, realTimeDB } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import constants from "../../../utils/constants";

const { DB_NAME, REALTIME_DB_NAME } = constants;

const useDeleteUserChat = (
  receiverUid,
  setIsLoading,
  setIsDeleteChatModalOpen,
  setIsChatboxModalOpen
) => {
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");

  const handleDeleteChat = async () => {
    setIsDeleteChatModalOpen(false);
    setIsLoading(true);

    await db
      .collection(DB_NAME.DELETE_CHAT)
      .doc(uid)
      .set(
        {
          deleteChatList: {
            [receiverUid]: Date.now(),
          },
        },
        { merge: true }
      );

    set(
      ref(
        realTimeDB,
        `${REALTIME_DB_NAME.PERSONAL_CHAT_INBOX}/` + `${uid}/` + receiverUid
      ),
      null
    );
    // set(ref(realTimeDB, `${REALTIME_DB_NAME.CHAT_PERSONAL}/` + chatRoom), null);
    setIsLoading(false);
    toast.success("Chat Deleted");
    setIsChatboxModalOpen(false);
  };

  return { handleDeleteChat };
};

export default useDeleteUserChat;
