import { useState, useEffect } from "react";
import isUserPremium from "../../stripe/isUserPremium";

export default function usePremiumStatus(user) {
  const [isLoading, setIsLoading] = useState(false);
  const [premiumStatus, setPremiumStatus] = useState(null);

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      const checkPremiumStatus = async function () {
        setPremiumStatus(await isUserPremium());
        setIsLoading(false);
      };
      checkPremiumStatus();
    }
  }, [user]);

  return { isLoading, premiumStatus };
}
