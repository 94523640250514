export const heightOptions = [
  {
    value: { feet: 4, inch: 0 },
    label: "4 feet (122 cm)",
  },
  {
    value: { feet: 4, inch: 1 },
    label: "4 feet 1 inch (124.5 cm)",
  },
  {
    value: { feet: 4, inch: 2 },
    label: "4 feet 2 inch (127 cm)",
  },
  {
    value: { feet: 4, inch: 3 },
    label: "4 feet 3 inch (129.5 cm)",
  },
  {
    value: { feet: 4, inch: 4 },
    label: "4 feet 4 inch (132 cm)",
  },
  {
    value: { feet: 4, inch: 5 },
    label: "4 feet 5 inch (134.5 cm)",
  },
  {
    value: { feet: 4, inch: 6 },
    label: "4 feet 6 inch (137 cm)",
  },
  {
    value: { feet: 4, inch: 7 },
    label: "4 feet 7 inch (139.5 cm)",
  },
  {
    value: { feet: 4, inch: 8 },
    label: "4 feet 8 inch (142 cm)",
  },
  {
    value: { feet: 4, inch: 9 },
    label: "4 feet 9 inch (144.5 cm)",
  },
  {
    value: { feet: 4, inch: 10 },
    label: "4 feet 10 inch (147 cm)",
  },
  {
    value: { feet: 4, inch: 11 },
    label: "4 feet 11 inch (149.5 cm)",
  },
  {
    value: { feet: 5, inch: 0 },
    label: "5 feet (152 cm)",
  },
  {
    value: { feet: 5, inch: 1 },
    label: "5 feet 1 inch (154.5 cm)",
  },
  {
    value: { feet: 5, inch: 2 },
    label: "5 feet 2 inch (157 cm)",
  },
  {
    value: { feet: 5, inch: 3 },
    label: "5 feet 3 inch (159.5 cm)",
  },
  {
    value: { feet: 5, inch: 4 },
    label: "5 feet 4 inch (162 cm)",
  },
  {
    value: { feet: 5, inch: 5 },
    label: "5 feet 5 inch (164.5 cm)",
  },
  {
    value: { feet: 5, inch: 6 },
    label: "5 feet 6 inch (167 cm)",
  },
  {
    value: { feet: 5, inch: 7 },
    label: "5 feet 7 inch (169.5 cm)",
  },
  {
    value: { feet: 5, inch: 8 },
    label: "5 feet 8 inch (172 cm)",
  },
  {
    value: { feet: 5, inch: 9 },
    label: "5 feet 9 inch (174.5 cm)",
  },
  {
    value: { feet: 5, inch: 10 },
    label: "5 feet 10 inch (177 cm)",
  },
  {
    value: { feet: 5, inch: 11 },
    label: "5 feet 11 inch (179.5 cm)",
  },
  {
    value: { feet: 6, inch: 0 },
    label: "6 feet (182 cm)",
  },
  {
    value: { feet: 6, inch: 1 },
    label: "6 feet 1 inch (184.5 cm)",
  },
  {
    value: { feet: 6, inch: 2 },
    label: "6 feet 2 inch (187 cm)",
  },
  {
    value: { feet: 6, inch: 3 },
    label: "6 feet 3 inch (189.5 cm)",
  },
  {
    value: { feet: 6, inch: 4 },
    label: "6 feet 4 inch (192 cm)",
  },
  {
    value: { feet: 6, inch: 5 },
    label: "6 feet 5 inch (194.5 cm)",
  },
  {
    value: { feet: 6, inch: 6 },
    label: "6 feet 6 inch (197 cm)",
  },
  {
    value: { feet: 6, inch: 7 },
    label: "6 feet 7 inch (199.5 cm)",
  },
  {
    value: { feet: 6, inch: 8 },
    label: "6 feet 8 inch (202 cm)",
  },
  {
    value: { feet: 6, inch: 9 },
    label: "6 feet 9 inch (204.5 cm)",
  },
  {
    value: { feet: 6, inch: 10 },
    label: "6 feet 10 inch (207 cm)",
  },
  {
    value: { feet: 6, inch: 11 },
    label: "6 feet 11 inch (209.5 cm)",
  },

  {
    value: { feet: 7, inch: 0 },
    label: "7 feet (212 cm)",
  },
  {
    value: { feet: 7, inch: 1 },
    label: "7 feet 1 inch (214.5 cm)",
  },
  {
    value: { feet: 7, inch: 2 },
    label: "7 feet 2 inch (217 cm)",
  },
  {
    value: { feet: 7, inch: 3 },
    label: "7 feet 3 inch (219.5 cm)",
  },
  {
    value: { feet: 7, inch: 4 },
    label: "7 feet 4 inch (222 cm)",
  },
  {
    value: { feet: 7, inch: 5 },
    label: "7 feet 5 inch (224.5 cm)",
  },
  {
    value: { feet: 7, inch: 6 },
    label: "7 feet 6 inch (227 cm)",
  },
  {
    value: { feet: 7, inch: 7 },
    label: "7 feet 7 inch (229.5 cm)",
  },
  {
    value: { feet: 7, inch: 8 },
    label: "7 feet 8 inch (232 cm)",
  },
  {
    value: { feet: 7, inch: 9 },
    label: "7 feet 9 inch (234.5 cm)",
  },
  {
    value: { feet: 7, inch: 10 },
    label: "7 feet 10 inch (237 cm)",
  },
  {
    value: { feet: 7, inch: 11 },
    label: "7 feet 11 inch (239.5 cm)",
  },
];

export const ageOptions = Array.from({ length: 33 }, (_, i) => ({
  value: i + 18,
  label: i + 18,
}));

export const relationshipStatusOptions = [
  { label: "Never Married", value: "Never Married" },
  { label: "Currently Separated", value: "Currently Separated" },
  { label: "Divorced", value: "Divorced" },
  { label: "Widow/Widower", value: "Widow/Widower" },
];

export const skinComplexionOptions = [
  { label: "Fair", value: "Fair" },
  { label: "Wheatish", value: "Wheatish" },
  { label: "Brown", value: "Brown" },
  { label: "Dark", value: "Dark" },
];

export const religionOptions = [
  { label: "Hindu", value: "Hindu" },
  { label: "Christianity", value: "Christianity" },
  { label: "Islam", value: "Islam" },
  { label: "No religion", value: "No religion" },
  { label: "Buddhism", value: "Buddhism" },
  { label: "Folk religions", value: "Folk religions" },
  { label: "Sikhism", value: "Sikhism" },
  { label: "Judaism", value: "Judaism" },
  { label: "Other", value: "Other" },
];

export const bodyTypeOptions = [
  { label: "Slim", value: "Slim" },
  { label: "Athletic", value: "Athletic" },
  { label: "Average", value: "Average" },
  { label: "A few extra pounds", value: "AFewExtraPounds" },
  { label: "Chubby", value: "Chubby" },
];

export const educationOptions = [
  { label: "High school", value: "HighSchool" },
  { label: "Bachelor Student", value: "BeachlorsStudent" },
  { label: "Masters Student", value: "MastersStudent" },
  { label: "Ph.D / Doctorate Student", value: "Ph.D/DoctorateStudent" },
  { label: "Bachelors in Engineering", value: "BachelorsInEngineering" },
  {
    label: "Bachelors in Management/Legal/Accounting",
    value: "BachelorsInManagement/Legal/Accounting",
  },
  { label: "Bachelors in Medical", value: "BachelorsInMedical" },
  { label: "Masters in Engineering", value: "MastersInEngineering" },
  {
    label: "Masters in Management/Legal/Accounting",
    value: "MastersInManagement/Legal/Accounting",
  },
  { label: "Masters in Medical", value: "MastersInMedical" },
  { label: "Bachelors in Others", value: "BachelorsInOthers" },
  { label: "Masters in Others", value: "MastersInOthers" },
  { label: "Ph.D / Doctorate", value: "Ph.D/Doctorate" },
  { label: "Other", value: "others" },
];

export const smokeOptions = [
  { label: "No Way", value: "No Way" },
  { label: "Occasionally", value: "Occasionally" },
  { label: "Daily", value: "Daily" },
  { label: "Cigar aficionado", value: "Cigar aficionado" },
  { label: "Yes, but trying to quit", value: "Yes, but trying to quit" },
];

export const drinkOptions = [
  { label: "Never", value: "Never" },
  { label: "Social Drinker", value: "Social Drinker" },
  { label: "Regularly", value: "Regularly" },
  { label: "Moderately", value: "Moderately" },
];

export const dietTypeOptions = [
  { label: "Veg", value: "Veg" },
  { label: "Non-Veg", value: "Non-Veg" },
  { label: "Occasionally Non-Veg", value: "Occasionally Non-Veg" },
  { label: "Eggetarian", value: "Eggetarian" },
  { label: "Vegan", value: "Vegan" },
];

export const exerciseOptions = [
  { label: "Don't exercise", value: "I don't exercise" },
  {
    label: "occasionally/randomly",
    value: "I exercise occasionally/randomly",
  },
  {
    label: "1-2 times per week",
    value: "I exercise 1-2 times per week",
  },
  {
    label: "3-4 times per week",
    value: "I exercise 3-4 times per week",
  },
  {
    label: "5 or more times per week",
    value: "I exercise 5 or more times per week",
  },
];

export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];
