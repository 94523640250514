import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

const CustomModal = ({ isOpen, CloseModal, title, onClickNo, onClickYes }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={CloseModal}
        ariaHideApp={false}
        contentLabel="custom-modal"
      >
        <p className="font-bold text-center mt-7 text-lg">{title}</p>
        <div className="flex justify-center mt-10">
          <button
            className="btn bg-[#fff] py-1 px-7 text-black rounded-md mr-3 hover:bg-black hover:text-white border-black border-2"
            onClick={onClickNo}
          >
            No
          </button>

          <button className="btn-primary" onClick={onClickYes}>
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "500px",
    padding: "10px 40px 40px",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    boxShadow: "5px 5px 7px #a44d91",
    borderWidth: "0 2px 2px 0",
    borderColor: "#a44d91",
  },
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)",
  },
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  CloseModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClickNo: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired,
};

CustomModal.defalutProps = {
  isOpen: false,
  CloseModal: () => {},
  title: "",
  onClickNo: () => {},
  onClickYes: () => {},
};

export default CustomModal;
