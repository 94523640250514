import { useState } from "react";
import { db } from "../../firebase";
import constants from "../../utils/constants";
import { isEmpty } from "lodash";

const { DB_NAME } = constants;

const useFetchUserDetailsById = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getUserDetailsById = async (uid) => {
    setIsLoading(true);
    let userData = null;

    if (!uid) return false;
    await db
      .collection(DB_NAME.USERS)
      .doc(uid)
      .get()
      .then((doc) => {
        if (!isEmpty(doc.data())) {
          userData = doc.data();
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    return userData;
  };

  return { isLoading, getUserDetailsById };
};

export default useFetchUserDetailsById;
