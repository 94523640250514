export const chatStr = {
  blockUserTitle: "Do you really want to Block this user?",
  unblockUserTitle: "Do you really want to Unblock this user?",
  deleteChatTitle: "Do you really want to delete chat of this user?",
  cancelSubscription: "Do you really want to cancel this subscription?",
};

export const subscriptionStr = {
  cancelSubscription: "Do you really want to cancel this subscription?",
};
