import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { ref, set } from "firebase/database";
import { toast } from "react-toastify";

import UserDetailsModal from "../../../components/modal/UserDetailsModal";
import ChatboxModal from "./ChatboxModal";
import useFetchPersonalChatInbox from "../../../hooks/chat/useFetchPersonalChatInbox";
import useFetchUserDetailsById from "../../../hooks/user/useFetchUserDetailsById";
import useFetchUserProfile from "../../../hooks/user/useFetchUserProfile";
import { useAuth } from "../../../contexts/AuthContext";
import { realTimeDB } from "../../../firebase";
import constants from "../../../utils/constants";
import { timeSince, getPlaceholderByGender } from "../../../utils/utils";
import MicrophoneIcon from "../../../assets/svgs/microphone.svg";

const { PATH, REALTIME_DB_NAME } = constants;

const PersonalInboxModal = ({
  isModalOpen,
  setIsPersonalInboxOpen,
  closeModal,
  fromWhere,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [isChatboxModalOpen, setIsChatboxModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { isLoading: isLoadingPersonalChatInbox, response: personalChatInbox } =
    useFetchPersonalChatInbox();
  const { isLoading: isLoadingUserDetails, getUserDetailsById } =
    useFetchUserDetailsById();
  const {
    isLoading: isLoadingProfile,
    isGotResponse: isGotProfileResponse,
    response: profileResponse,
  } = useFetchUserProfile();
  const loading =
    isLoadingPersonalChatInbox || isLoadingUserDetails || isLoadingProfile;

  const handleModalClose = () => {
    setIsUserDetailsModalOpen(false);
    setSelectedUser(null);
  };

  const handleImageError = (event, gender = "") => {
    return (event.target.src = getPlaceholderByGender(gender));
  };

  const handleClickChatCard = (item) => {
    set(
      ref(
        realTimeDB,
        `${REALTIME_DB_NAME.PERSONAL_CHAT_INBOX}/` +
          `${uid}/` +
          item.receiverUid
      ),
      {
        ...item,
        unread: false,
      }
    );
    setIsChatboxModalOpen(true);
    setSelectedUser(item);
    // closeModal();
    // navigate(`${PATH.CHATBOX}`, {
    //   state: {
    //     receiverUid: item.receiverUid,
    //     userProfileDetails: profileResponse,
    //     fromWhere,
    //   },
    // });
  };

  const handleOpenUserDetails = async (e, uid) => {
    e.stopPropagation();
    const response = await getUserDetailsById(uid);

    if (response) {
      setIsUserDetailsModalOpen(true);
      setSelectedUser(response);
    } else {
      toast.error("Profile not found.");
    }
  };

  const handleProfileFormNavigate = () => {
    if (isEmpty(profileResponse?.name) && isEmpty(profileResponse?.gender)) {
      navigate(PATH.PROFILE_FORM);
    }
  };

  useEffect(() => {
    if (isGotProfileResponse) {
      handleProfileFormNavigate();
    }
  }, [isGotProfileResponse]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={customStyles}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="personal-chat-inbox-modal"
        className={
          "w-100 bg-white p-4 w-auto rounded-lg shadow-2xl fixed bottom-0 right-0 ms-10 max-h-[80vh] overflow-y-auto"
        }
      >
        <div className="flex justify-center">
          <div>
            {!isEmpty(personalChatInbox) ? (
              personalChatInbox.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`card px-4 py-2 flex justify-between items-center cursor-pointer mt-5 relative`}
                    onClick={() => handleClickChatCard(item)}
                  >
                    <div
                      className="avatar-image flex-grow-1"
                      onClick={(e) =>
                        handleOpenUserDetails(e, item.receiverUid)
                      }
                    >
                      <img
                        src={
                          item?.avatar
                            ? item.avatar
                            : getPlaceholderByGender(item?.gender)
                        }
                        alt="Avatar"
                        onError={(event) =>
                          handleImageError(event, item?.gender)
                        }
                      />
                    </div>

                    <div className="mb-4 flex-1">
                      <p className="font-bold">{get(item, "name", "")}</p>
                      <div className="flex">
                        {(item?.message === "Voice Message" || item?.audio) && (
                          <div className="mt-2 mr-2">
                            <img
                              src={MicrophoneIcon}
                              alt="microphone"
                              height={20}
                              width={20}
                            />
                          </div>
                        )}
                        <div className="mt-1">{get(item, "message", "")}</div>
                      </div>
                    </div>

                    <div className="text-right">
                      {item?.unread && (
                        <p className="text-white bg-[#5d5fef] border rounded-s-full mt-2 text-center absolute right-0 top-0 px-3">
                          new
                        </p>
                      )}
                      <p className="text-pink font-semibold">
                        {timeSince(get(item, "createdAt", 0))}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-center text-2xl font-bold py-8 mx-20">
                Chat Not Found
              </p>
            )}
          </div>

          <UserDetailsModal
            isModalOpen={isUserDetailsModalOpen}
            closeModal={handleModalClose}
            selectedUser={selectedUser}
            loggedInUserProfile={profileResponse}
          />

          {isChatboxModalOpen && (
            <ChatboxModal
              isModalOpen={isChatboxModalOpen}
              setIsChatboxModalOpen={setIsChatboxModalOpen}
              closeModal={() => setIsChatboxModalOpen(false)}
              receiverUid={get(selectedUser, "receiverUid", null)}
              userProfileDetails={profileResponse}
              fromWhere={fromWhere}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

PersonalInboxModal.defalutProps = {
  isModalOpen: false,
  setIsPersonalInboxOpen: () => {},
  closeModal: () => {},
  fromWhere: "",
};

export default PersonalInboxModal;

const customStyles = {
  overlay: {
    backgroundColor: "transparent",
  },
};
