// TODO: When we use HashRouter is App.js then use ROUTE_STR = HASH_ROUTE_STR
const HASH_ROUTE_STR = `#/`;
const WITHOUTHASH_ROUTE_STR = ``;
const ROUTE_STR = WITHOUTHASH_ROUTE_STR;
const STRIPE_SUCCESS_URL = {
  HOME: `${window.location.origin}`,
  PROFILE: `${window.location.origin}/${ROUTE_STR}profile`,
  CHATBOX: `${window.location.origin}/${ROUTE_STR}chatbox`,
  CHAT_INBOX: `${window.location.origin}/${ROUTE_STR}chat`,
  SUBSCRIPTION: `${window.location.origin}/${ROUTE_STR}subscription`,
};
const STRIPE_CANCEL_URL = {
  HOME: `${window.location.origin}`,
  PROFILE: `${window.location.origin}/${ROUTE_STR}profile`,
  CHATBOX: `${window.location.origin}/${ROUTE_STR}chatbox`,
  CHAT_INBOX: `${window.location.origin}/${ROUTE_STR}chat`,
  SUBSCRIPTION: `${window.location.origin}/${ROUTE_STR}subscription`,
};

const TEST_PRICE_ID = {
  MONTHLY_VIP: "price_1OVC3vCzhmJBOxMROwQAocYW",
  QUARTERLY_VIP: "price_1OUPihCzhmJBOxMR3MMPCIH0",
  YEARLY_VIP: "price_1OUPkECzhmJBOxMREwyOedmU",
};
const PROD_PRICE_ID = {
  MONTHLY_VIP: "price_1OU50iCzhmJBOxMRsSonmKZK",
  QUARTERLY_VIP: "price_1OU7K1CzhmJBOxMRyk0XKdFu",
  YEARLY_VIP: "price_1OU7M8CzhmJBOxMR7iD7houd",
};

const STRIPE_ROLE = {
  MONTHLY_VIP: "monthlyVIP",
  QUARTERLY_VIP: "quarterlyVIP",
  YEARLY_VIP: "yearlyVIP",
};
const STRIPE_CHAT_PLAN_OPTIONS = [
  STRIPE_ROLE.MONTHLY_VIP,
  STRIPE_ROLE.QUARTERLY_VIP,
  STRIPE_ROLE.YEARLY_VIP,
];

const LOADING_TIMEOUT = 5000;
const STRIPE_TESTING_EMAIL = [
  "saurabhpatel19971997@gmail.com",
  "testing1@gmail.com",
  "amitpatel321@gmail.com",
];

const PREMIUM_STATUS = {
  monthlyVIP: "1 Month",
  quarterlyVIP: "3 Month",
  yearlyVIP: "12 Month",
};

export default {
  STRIPE_SUCCESS_URL,
  STRIPE_CANCEL_URL,
  TEST_PRICE_ID,
  PROD_PRICE_ID,
  STRIPE_ROLE,
  STRIPE_CHAT_PLAN_OPTIONS,
  LOADING_TIMEOUT,
  STRIPE_TESTING_EMAIL,
  PREMIUM_STATUS,
};
