import React, { useState } from "react";
import Modal from "react-modal";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Select from "react-select";

import ImageCarousel from "../common/ImageCarousel";
import { genderOptions } from "../../utils/data/profileFieldOptions";
import chat from "../../assets/svgs/chat.svg";
import leftArrow from "../../assets/svgs/arrow-left.svg";
import ChatboxModal from "../chat/modal/ChatboxModal";

const UserDetailsModal = ({
  isModalOpen,
  closeModal,
  selectedUser,
  loggedInUserProfile,
  isShowChatIcon,
  isUpdateGender,
  handleChangeGender,
  handleUpdateGender,
}) => {
  const [isChatboxModalOpen, setIsChatboxModalOpen] = useState(false);
  const userImageList = [
    get(selectedUser, "Img1", ""),
    get(selectedUser, "Img2", ""),
    get(selectedUser, "Img3", ""),
    get(selectedUser, "Img4", ""),
    get(selectedUser, "Img5", ""),
  ].filter((item) => !isEmpty(item));

  const handleClickChatIcon = () => {
    closeModal();
    setIsChatboxModalOpen(true);
    // navigate(`${PATH.CHATBOX}`, {
    //   state: {
    //     receiverUid: get(selectedUser, "uid", ""),
    //     userProfileDetails: loggedInUserProfile,
    //   },
    // });
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={customStyles}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="user-details-modal"
      >
        <div className="flex justify-center mt-2">
          <div className="mx-10">
            <div className="card mb-10">
              <div className="bg-image pt-3">
                {isShowChatIcon && (
                  <div
                    className="float-right cursor-pointer"
                    onClick={handleClickChatIcon}
                  >
                    <img
                      src={chat}
                      className="mt-2 me-3 animate-pulse hover:animate-none [animation-delay:0.6s]"
                      alt="chat"
                      width={40}
                      height={40}
                      title="chat"
                      draggable={false}
                    />
                  </div>
                )}

                <div
                  className="float-left cursor-pointer"
                  onClick={() => closeModal()}
                >
                  <img
                    src={leftArrow}
                    className="mt-2 ms-3"
                    alt="back arrow"
                    width={32}
                    height={32}
                  />
                </div>

                <ImageCarousel
                  images={userImageList}
                  gender={selectedUser?.gender}
                  isDotHidden={false}
                />
              </div>

              <div className="card-body">
                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">About: </span>
                  <span className="ml-1">
                    {get(selectedUser, "aboutMe", "")}
                  </span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Name: </span>
                  <span className="ml-1">{get(selectedUser, "name", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1 flex">
                  <span className="font-bold text-md">Gender: </span>
                  {isUpdateGender ? (
                    <div className="flex justify-between w-full ml-4">
                      <Select
                        className=""
                        isMulti={false}
                        options={genderOptions}
                        value={{
                          label: get(selectedUser, "gender", ""),
                          value: get(selectedUser, "gender", ""),
                        }}
                        placeholder="Select Gender"
                        onChange={(value) => handleChangeGender(value)}
                        isClearable={true}
                      />
                      <button
                        className="btn-primary"
                        onClick={handleUpdateGender}
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    <span className="ml-1">
                      {get(selectedUser, "gender", "")}
                    </span>
                  )}
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Location: </span>
                  <span className="ml-1">
                    {get(selectedUser, "address", "")}
                  </span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Height: </span>
                  <span className="ml-1">
                    {`${selectedUser?.heightft} feet ${
                      selectedUser?.heightInch
                        ? selectedUser?.heightInch + " inch"
                        : ""
                    }`}
                  </span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Age: </span>
                  <span className="ml-1">{get(selectedUser, "age", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">
                    Relationship status:{" "}
                  </span>
                  <span className="ml-1">
                    {get(selectedUser, "relationship", "")}
                  </span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Skin complexion: </span>
                  <span className="ml-1">{get(selectedUser, "skin", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Religion: </span>
                  <span className="ml-1">
                    {get(selectedUser, "religion", "")}
                  </span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Body type: </span>
                  <span className="ml-1">{get(selectedUser, "body", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Education: </span>
                  <span className="ml-1">
                    {get(selectedUser, "education", "")}
                  </span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">
                    How often you Smoke?{" "}
                  </span>
                  <span className="ml-1">{get(selectedUser, "smoke", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">
                    How often you Drink?
                  </span>
                  <span className="ml-1">{get(selectedUser, "drink", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Diet type: </span>
                  <span className="ml-1">{get(selectedUser, "diet", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">
                    {`What's your Often exercise?`}
                  </span>
                  <span className="ml-1">
                    {get(selectedUser, "exercise", "")}
                  </span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Have any Kids? </span>
                  <span className="ml-1">{get(selectedUser, "kids", "")}</span>
                </div>

                <div className="mt-3 border-b-4 pb-1">
                  <span className="font-bold text-md">Have any Pets? </span>
                  <span className="ml-1">{get(selectedUser, "pets", "")}</span>
                </div>

                <div className="mt-3">
                  <span className="font-bold text-md">Occupation: </span>
                  <span className="ml-1">
                    {get(selectedUser, "occupation", "")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {isChatboxModalOpen && (
        <ChatboxModal
          setIsChatboxModalOpen={setIsChatboxModalOpen}
          receiverUid={get(selectedUser, "uid", "")}
          userProfileDetails={loggedInUserProfile}
        />
      )}
    </>
  );
};

UserDetailsModal.defalutProps = {
  isModalOpen: false,
  closeModal: () => {},
  selectedUser: null,
  loggedInUserProfile: null,
  isShowChatIcon: true,
  isUpdateGender: false,
  handleChangeGender: () => {},
  handleUpdateGender: () => {},
};

export default UserDetailsModal;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "650px",
    padding: "10px 20px",
    maxHeight: "calc(100vh - 60px)",
    overflowY: "auto",
    tab: "",
    backgroundColor: "#a44d91",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)",
  },
};
