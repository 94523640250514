import get from "lodash/get";
import { ref, set } from "firebase/database";
import { toast } from "react-toastify";
import { realTimeDB } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import constants from "../../../utils/constants";
import { getChatRoom, getMessageLocalLimit } from "../../../helpers/ChatHelper";
import { messageIdGenerator, uuidGenerator } from "../../../utils/utils";

const { REALTIME_DB_NAME, MAX_CONSECUTIVE_MESSAGE_LIMIT } = constants;

const useSendMessage = (
  chatInput,
  setChatInput,
  isYouBlockedReceiverUser,
  personalChatResponse,
  receiverProfileResponse,
  userProfileDetails
) => {
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");

  const handleSendMessage = () => {
    if (isYouBlockedReceiverUser) {
      setChatInput("");
      toast.error("You can't send the message. User blocked.");
      return;
    }
    if (!chatInput.trim()) return;
    const localLimit = getMessageLocalLimit(personalChatResponse, uid);
    if (localLimit >= MAX_CONSECUTIVE_MESSAGE_LIMIT) {
      setChatInput("");
      toast.error(`You sent ${MAX_CONSECUTIVE_MESSAGE_LIMIT} messages without reply.
            Wait for the reply :)`);
      return;
    }

    const receiverUid = get(receiverProfileResponse, "uid", "");
    const input = {
      createdAt: Date.now(),
      _id: uuidGenerator(),
      text: chatInput,
      user: {
        _id: uid,
        avatar: get(userProfileDetails, "imageUrl", ""),
        email: get(userProfileDetails, "email", ""),
        name: get(userProfileDetails, "name", ""),
        receiver: {
          _id: receiverUid,
          avatar: get(receiverProfileResponse, "imageUrl", ""),
          email: get(receiverProfileResponse, "email", ""),
          name: get(receiverProfileResponse, "name", ""),
        },
      },
    };

    const personalChatInboxInput = {
      createdAt: Date.now(),
      avatar: get(receiverProfileResponse, "imageUrl", ""),
      message: chatInput,
      name: get(receiverProfileResponse, "name", ""),
      receiverUid,
      senderUid: uid,
      unread: false,
      gender: get(receiverProfileResponse, "gender", ""),
    };

    const personalChatReceiverInboxInput = {
      createdAt: Date.now(),
      avatar: get(userProfileDetails, "imageUrl", ""),
      message: chatInput,
      name: get(userProfileDetails, "name", ""),
      receiverUid: uid,
      senderUid: receiverUid,
      unread: true,
      gender: get(userProfileDetails, "gender", ""),
    };

    set(
      ref(
        realTimeDB,
        `${REALTIME_DB_NAME.PERSONAL_CHAT_INBOX}/` + `${receiverUid}/` + uid
      ),
      { ...personalChatReceiverInboxInput }
    );

    set(
      ref(
        realTimeDB,
        `${REALTIME_DB_NAME.PERSONAL_CHAT_INBOX}/` + `${uid}/` + receiverUid
      ),
      { ...personalChatInboxInput }
    );

    set(
      ref(
        realTimeDB,
        `${REALTIME_DB_NAME.CHAT_PERSONAL}/` +
          `${getChatRoom(uid, receiverUid)}/` +
          messageIdGenerator()
      ),
      {
        ...input,
      }
    );
    setChatInput("");
  };

  return { handleSendMessage };
};

export default useSendMessage;
