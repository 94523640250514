const MONTHLY_VIP_PRICE = {
  INR: "₹699",
  USD: "$8.5",
};

const QUARTERLY_VIP__PRICE = {
  INR: "₹720",
  USD: "$9",
};

const YEARLY_VIP_PRICE = {
  INR: "₹4999",
  USD: "$60",
};

export default {
  MONTHLY_VIP_PRICE,
  QUARTERLY_VIP__PRICE,
  YEARLY_VIP_PRICE,
};
