import malePlaceholder from "../assets/images/male-placeholder.jpg";
import femalePlaceholder from "../assets/images/female-placeholder.png";
import placeholder from "../assets/svgs/placeholder.svg";

import constants from "./constants";

const { CLOUD_FUN_PROD_URL, CLOUD_FUN_LOCAL_URL } = constants;

export const getCloudFunctionUrl = () => {
  const cloudFunctionUrl = CLOUD_FUN_PROD_URL;
  // const cloudFunctionUrl = CLOUD_FUN_LOCAL_URL;
  return cloudFunctionUrl;
};

export function timeSince(timeStamp) {
  if (!timeStamp) return "O sec";

  var now = new Date(),
    secondsPast = (now.getTime() - timeStamp) / 1000;
  if (secondsPast < 60) {
    return "just now";
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + " min ago";
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + " hours ago";
  }
  if (secondsPast > 86400) {
    let day = new Date(timeStamp);
    return day.toDateString();
  }
}

export const uuidGenerator = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const messageIdGenerator = () => {
  return "-Nxxxxxxx4xxxx-4xxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 15) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(15);
  });
};

export const getPlaceholderByGender = (gender) => {
  if (gender === "Male") {
    return malePlaceholder;
  } else if (gender === "Female") {
    return femalePlaceholder;
  } else {
    return placeholder;
  }
};

const convertToCSV = (objArray) => {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  for (var i = 0; i < array.length; i++) {
    let line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

export const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers);
  }

  const jsonObject = JSON.stringify(items);
  const csv = convertToCSV(jsonObject);
  const exportedFilenmae = fileTitle + ".csv" || "export.csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
