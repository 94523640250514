import get from "lodash/get";
import firebase from "firebase/compat/app";
import { ref, set } from "firebase/database";
import { db, realTimeDB } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import constants from "../../utils/constants";

const { DB_NAME, REALTIME_DB_NAME } = constants;

const useSetStatusOffline = () => {
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");

  const handleSetStatusOffile = () => {
    db.collection(DB_NAME.USERS).doc(uid).set(
      {
        status: "offline",
        lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );

    set(ref(realTimeDB, `${REALTIME_DB_NAME.STATUS}/` + `${uid}/`), {
      status: "offline",
      lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  return { handleSetStatusOffile };
};

export default useSetStatusOffline;
