import React, { useState, useEffect, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import { getPlaceholderByGender } from "../../utils/utils";
import leftArrow from "../../assets/svgs/left-arrow-carousel.svg";

const ImageCarousel = ({ images, isDotHidden, gender }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(
    (index) => {
      setCurrentIndex(index);
      setIsViewerOpen(true);
    },
    [currentIndex]
  );

  const closeImageViewer = () => {
    setCurrentIndex(0);
    setIsViewerOpen(false);
  };

  const handlePrev = () => {
    setCurrentIndex(currentIndex ? currentIndex - 1 : images.length - 1);
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
  };

  const handleImageError = (event) => {
    return (event.target.src = getPlaceholderByGender(gender));
  };

  useEffect(() => {
    let timer = null;

    if (images.length > 1) {
      setTimeout(() => {
        handleNext();
      }, 4000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [currentIndex, images]);

  return (
    <>
      <div className="static max-w-screen-lg w-full">
        <div className="flex items-center justify-center m-2 static">
          <button
            className="m-2 p-1 hover:bg-black rounded-full"
            onClick={handlePrev}
          >
            <img
              src={leftArrow}
              alt="left-arrow"
              width={32}
              draggable={false}
            />
          </button>

          {images &&
            images.map((image, index) => {
              return (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${currentIndex + 1}`}
                  className={`profile-image ${
                    currentIndex !== index && "hidden"
                  }`}
                  onError={handleImageError}
                  draggable={false}
                  onClick={() => openImageViewer(currentIndex)}
                />
              );
            })}

          <button
            className="m-2 p-1 hover:bg-black rounded-full"
            onClick={handleNext}
          >
            <img
              src={leftArrow}
              className="rotate-180"
              alt="right-arrow"
              width={32}
              draggable={false}
            />
          </button>
        </div>

        <div className="carousel-dots">
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-4 h-4 mx-2 cursor-pointer ${
                index === currentIndex ? "bg-[#5d5fef]" : "bg-gray-300"
              } rounded-full`}
              hidden={isDotHidden}
            />
          ))}
        </div>
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

ImageCarousel.defalutProps = {
  isDotHidden: false,
  images: [],
  gender: "",
};

export default ImageCarousel;
