import isEmpty from "lodash/isEmpty";
import stripeConstants from "./stripeConstants";

const { STRIPE_ROLE } = stripeConstants;

export const getIsMonthlyVipBtnDisable = (premiumStatus) => {
  return [
    STRIPE_ROLE.MONTHLY_VIP,
    STRIPE_ROLE.QUARTERLY_VIP,
    STRIPE_ROLE.YEARLY_VIP,
  ].includes(premiumStatus);
};

export const getIsQuartlyVipBtnDisable = (premiumStatus) => {
  return [STRIPE_ROLE.QUARTERLY_VIP, STRIPE_ROLE.YEARLY_VIP].includes(
    premiumStatus
  );
};

export const getIsYearlyVipBtnDisable = (premiumStatus) => {
  return [STRIPE_ROLE.YEARLY_VIP].includes(premiumStatus);
};

// check is there any plan active for Chat
export const getIsAnySubsActiveByPlanOptions = (
  allSubscriptions = [],
  planOptions = []
) => {
  let isActiveCount = 0;
  let temp = [];

  if (!isEmpty(allSubscriptions)) {
    const currentDateInNanoSec = Date.parse(new Date()) / 1000;

    allSubscriptions.forEach((item) => {
      planOptions.forEach((plan) => {
        if (
          currentDateInNanoSec <= item.data.current_period_end.seconds &&
          item.data.role === plan
        ) {
          temp.push(item);
          isActiveCount = isActiveCount + 1;
        }
      });
    });
  }

  return isActiveCount ? true : false;
};
