import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { ref, set } from "firebase/database";
import { toast } from "react-toastify";

import Loader from "../../../components/common/UserBouncingLoader";
import CustomModal from "../../../components/modal/CustomModal";
import UserDetailsModal from "../../../components/modal/UserDetailsModal";
import VipSubscriptionModal from "../../../components/subscription/VipSubscriptionModal";

import useFetchUserProfile from "../../../hooks/user/useFetchUserProfile";
import useFetchPersonalChat from "../../../hooks/chat/useFetchPersonalChat";
import useFetchUserDetailsById from "../../../hooks/user/useFetchUserDetailsById";
import useFetchIsYouBlockedReceiver from "../../../hooks/chat/useFetchIsYouBlockedReceiver";
import useFetchIsReceiverBlockedYou from "../../../hooks/chat/useFetchIsReceiverBlockedYou";
import useFetchPersonalChatInbox from "../../../hooks/chat/useFetchPersonalChatInbox";
import usePremiumStatus from "../../../hooks/stripe/usePremiumStatus";
import useFetchAllSubs from "../../../hooks/stripe/useFetchAllSubs";
import useBlockUser from "../../../hooks/chat/chatbox/useBlockUser";
import useUnblockUser from "../../../hooks/chat/chatbox/useUnblockUser";
import useDeleteUserChat from "../../../hooks/chat/chatbox/useDeleteUserChat";
import useSendMessage from "../../../hooks/chat/chatbox/useSendMessage";

import { realTimeDB } from "../../../firebase";
import { getIsExceedFreeChatLimit } from "../../../helpers/ChatHelper";
import { useAuth } from "../../../contexts/AuthContext";
import { getIsAnySubsActiveByPlanOptions } from "../../../stripe/function";
import { timeSince, getPlaceholderByGender } from "../../../utils/utils";
import constants from "../../../utils/constants";
import stripeConstants from "../../../stripe/stripeConstants";

import { chatStr } from "../../../utils/string";
import menuImage from "../../../assets//svgs/menu-vertical.svg";
import closeIcon from "../../../assets//svgs/close-white.svg";
import sendImage from "../../../assets//icons/send.png";

const { PATH, REALTIME_DB_NAME } = constants;
const { STRIPE_SUCCESS_URL, STRIPE_CANCEL_URL, STRIPE_CHAT_PLAN_OPTIONS } =
  stripeConstants;

const ChatboxModal = ({
  setIsChatboxModalOpen,
  receiverUid,
  userProfileDetails,
}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const uid = get(currentUser, "uid", "");

  const bottomMessageRef = useRef(null);
  const chatInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [isYouBlockedReceiverUser, setIsYouBlockedReceiverUser] =
    useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteChatModalOpen, setIsDeleteChatModalOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isVipSubsModalOpen, setIsVipSubsModalOpen] = useState(false);

  const { isLoading: isLoadingPersonalChats, response: personalChatResponse } =
    useFetchPersonalChat(receiverUid);
  const {
    isLoading: isLoadingReceiverProfile,
    response: receiverProfileResponse,
  } = useFetchUserProfile(receiverUid);
  const { isLoading: isLoadingUserDetails, getUserDetailsById } =
    useFetchUserDetailsById();
  const {
    isLoading: isLoadingReceiverBlocked,
    isYouBlockedReceiver,
    isGotResponse: isGotReceiverBlockedResponse,
  } = useFetchIsYouBlockedReceiver(receiverUid);
  const { isLoading: isLoadingReceiverBlockedYou, isReceiverBlockedYou } =
    useFetchIsReceiverBlockedYou(receiverUid);
  const { isLoading: isLoadingPersonalChatInbox, response: personalChatInbox } =
    useFetchPersonalChatInbox();
  const { premiumStatus } = usePremiumStatus(currentUser);
  const { isLoading: isFetchAllSubsLoading, allSubscriptions } =
    useFetchAllSubs();
  const { handleBlock } = useBlockUser(
    receiverUid,
    setIsYouBlockedReceiverUser,
    setIsLoading,
    setIsOpenModal
  );
  const { handleUnBlock } = useUnblockUser(
    receiverUid,
    setIsYouBlockedReceiverUser,
    setIsLoading,
    setIsOpenModal
  );
  const { handleDeleteChat } = useDeleteUserChat(
    receiverUid,
    setIsLoading,
    setIsDeleteChatModalOpen,
    setIsChatboxModalOpen
  );
  const { handleSendMessage } = useSendMessage(
    chatInput,
    setChatInput,
    isYouBlockedReceiverUser,
    personalChatResponse,
    receiverProfileResponse,
    userProfileDetails
  );

  const isAnyPlanActive = getIsAnySubsActiveByPlanOptions(
    allSubscriptions,
    STRIPE_CHAT_PLAN_OPTIONS
  );
  const loading =
    isLoadingPersonalChats ||
    isLoadingReceiverProfile ||
    isLoadingUserDetails ||
    isLoadingReceiverBlocked ||
    isLoading ||
    isLoadingPersonalChatInbox ||
    isLoadingReceiverBlockedYou ||
    isFetchAllSubsLoading;

  const handleModalClose = () => {
    setIsUserDetailsModalOpen(false);
    setSelectedUser(null);
  };

  const handleChangeUnread = () => {
    const [filteredPersonalChatInboxObj = {}] = personalChatInbox.filter(
      (item) => item.receiverUid === receiverUid
    );

    if (
      !isEmpty(filteredPersonalChatInboxObj) &&
      filteredPersonalChatInboxObj?.message
    ) {
      set(
        ref(
          realTimeDB,
          `${REALTIME_DB_NAME.PERSONAL_CHAT_INBOX}/` + `${uid}/` + receiverUid
        ),
        { ...filteredPersonalChatInboxObj, unread: false }
      );
    }
  };

  useEffect(() => {
    bottomMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    handleChangeUnread();
  }, [personalChatResponse]);

  const handleCurrentUserImageError = (event) => {
    return (event.target.src = getPlaceholderByGender(
      userProfileDetails?.gender
    ));
  };
  const handleReceiverImageError = (event) => {
    return (event.target.src = getPlaceholderByGender(
      receiverProfileResponse?.gender
    ));
  };

  const handleOpenUserDetails = async (e, uid) => {
    e.stopPropagation();
    const response = await getUserDetailsById(uid);
    if (response) {
      setIsUserDetailsModalOpen(true);
      setSelectedUser(response);
    } else {
      toast.error("Profile not found.");
    }
  };

  const handleKeyDownInput = (event) => {
    if (event.key === "Enter") {
      handleClickSend();
    }
  };

  const handleClickSend = () => {
    const isLimitExceed = getIsExceedFreeChatLimit(
      personalChatResponse,
      uid,
      userProfileDetails?.gender
    );
    if (isLimitExceed && !isAnyPlanActive) {
      setIsVipSubsModalOpen(true);
    } else {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (isGotReceiverBlockedResponse) {
      setIsYouBlockedReceiverUser(isYouBlockedReceiver);
    }
  }, [isGotReceiverBlockedResponse]);

  useEffect(() => {
    if (chatInputRef.current) {
      chatInputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className="outline-none rounded-lg fixed bottom-0 right-[392px] w-[382px]">
        {loading && <Loader />}

        <div className="chat-container">
          <div className="chat-header">
            <button
              className="ms-5"
              onClick={() => setIsChatboxModalOpen(false)}
            >
              <img src={closeIcon} width={22} height={22} />
            </button>

            <div className="text-center">
              <p className="font-bold text-lg">
                {get(receiverProfileResponse, "name", "")}
              </p>
              <p>
                {receiverProfileResponse?.status === "online"
                  ? "Online"
                  : `Last seen - ${timeSince(
                      receiverProfileResponse?.lastLogin?.toDate()
                    )}`}
              </p>
            </div>

            <div className="justify-end flex-grow-1 me-3 relative">
              <button onClick={() => setOpenDropdown(!openDropdown)}>
                <img src={menuImage} width={22} height={22} />
              </button>

              <div
                className={`bg-[#ffffff] text-black rounded-md rounded-t-none w-40 border border-[gray] absolute top-10 right-0 ${
                  openDropdown ? "max-h-auto" : "hidden"
                }`}
              >
                <ul className="p-1">
                  <button
                    className="p-2 text-red-500 hover:bg-[#5d5fef] hover:text-white w-full hover:rounded font-semibold"
                    onClick={() => {
                      setIsOpenModal(true);
                      setOpenDropdown(!openDropdown);
                    }}
                  >
                    {isYouBlockedReceiverUser ? "Unblock" : "Block User"}
                  </button>
                  <button
                    className="p-2 text-red-500 hover:bg-[#5d5fef] hover:text-white w-full hover:rounded font-semibold border-b-2 border-black-100"
                    onClick={() => {
                      setIsDeleteChatModalOpen(true);
                      setOpenDropdown(!openDropdown);
                    }}
                  >
                    Delete Chat
                  </button>
                </ul>
              </div>
            </div>
          </div>

          <div className="chat-content">
            {!isEmpty(personalChatResponse) ? (
              personalChatResponse.map((item, index) => {
                return (
                  <div key={index}>
                    {uid === item?.user?._id &&
                    item?.messageType !== "audio" ? (
                      <div
                        className={`py-1 flex items-center justify-end ${
                          index !== 0 && "mt-2"
                        }`}
                      >
                        <div
                          className={`${
                            uid === item?.user?._id &&
                            "bg-[#939dd5] text-white rounded-s-md border-e-4 border-[#5d5fef] max-w-xs ms-1"
                          }`}
                        >
                          <div className="mt-1 px-5 py-2">
                            <p>
                              {item.messageType == "audio" ? (
                                <p>Audio</p>
                              ) : (
                                get(item, "text", "")
                              )}
                            </p>
                            <p className="text-xs">
                              {timeSince(get(item, "createdAt", 0))}
                            </p>
                          </div>
                        </div>
                        <div
                          className="personal-chat-avatar me-1 cursor-pointer"
                          onClick={() => navigate(PATH.PROFILE)}
                        >
                          <img
                            loading="lazy"
                            src={
                              item?.user?.avatar
                                ? item.user.avatar
                                : getPlaceholderByGender(
                                    userProfileDetails?.gender
                                  )
                            }
                            alt="Avatar"
                            onError={handleCurrentUserImageError}
                          />
                        </div>
                      </div>
                    ) : uid !== item?.user?._id &&
                      item?.messageType !== "audio" ? (
                      <div
                        className={`py-1 flex items-center justify-start ${
                          index !== 0 && "mt-2"
                        }`}
                      >
                        <div
                          className="personal-chat-avatar me-1 cursor-pointer"
                          onClick={(e) =>
                            handleOpenUserDetails(e, item.user._id)
                          }
                        >
                          <img
                            loading="lazy"
                            src={
                              item?.user?.avatar
                                ? item.user.avatar
                                : getPlaceholderByGender(
                                    receiverProfileResponse?.gender
                                  )
                            }
                            alt="Avatar"
                            onError={handleReceiverImageError}
                          />
                        </div>

                        <div className="bg-[#f4f4f4] text-black rounded-e-md border-s-4 border-[gray] max-w-xs">
                          <div className="mt-1 px-5 py-2">
                            <p>
                              {item.messageType == "audio" ? (
                                <p>Audio</p>
                              ) : (
                                get(item, "text", "")
                              )}
                            </p>
                            <p className="text-xs">
                              {timeSince(get(item, "createdAt", 0))}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })
            ) : (
              <></>
            )}
            <div ref={bottomMessageRef}></div>
          </div>

          <div className="chat-footer">
            {isReceiverBlockedYou !== true && (
              <div className="flex justify-between">
                <input
                  ref={chatInputRef}
                  className="chat-input"
                  type="text"
                  placeholder="Type a message..."
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  onKeyDown={handleKeyDownInput}
                />

                <button
                  className="bg-[#a44d91] text-white rounded-md w-20 h-10 mt-4 me-3"
                  disabled={!chatInput.trim()}
                  onClick={() => handleClickSend()}
                >
                  <img
                    src={sendImage}
                    width={32}
                    height={30}
                    className="ms-3"
                  />
                </button>
              </div>
            )}
          </div>
        </div>

        {isYouBlockedReceiverUser ? (
          <CustomModal
            title={chatStr.unblockUserTitle}
            isOpen={isOpenModal}
            CloseModal={() => setIsOpenModal(false)}
            onClickNo={() => setIsOpenModal(false)}
            onClickYes={handleUnBlock}
          />
        ) : (
          <CustomModal
            title={chatStr.blockUserTitle}
            isOpen={isOpenModal}
            CloseModal={() => setIsOpenModal(false)}
            onClickNo={() => setIsOpenModal(false)}
            onClickYes={handleBlock}
          />
        )}

        <CustomModal
          title={chatStr.deleteChatTitle}
          isOpen={isDeleteChatModalOpen}
          CloseModal={() => setIsDeleteChatModalOpen(false)}
          onClickNo={() => setIsDeleteChatModalOpen(false)}
          onClickYes={handleDeleteChat}
        />

        <UserDetailsModal
          isModalOpen={isUserDetailsModalOpen}
          closeModal={handleModalClose}
          selectedUser={selectedUser}
          loggedInUserProfile={userProfileDetails}
        />

        <VipSubscriptionModal
          isModalOpen={isVipSubsModalOpen}
          closeModal={() => setIsVipSubsModalOpen(false)}
          setIsLoading={setIsLoading}
          premiumStatus={premiumStatus}
          successUrl={STRIPE_SUCCESS_URL.HOME}
          cancelUrl={STRIPE_CANCEL_URL.HOME}
        />
      </div>
    </>
  );
};

ChatboxModal.defalutProps = {
  setIsChatboxModalOpen: () => {},
  receiverUid: null,
  userProfileDetails: null,
};

export default ChatboxModal;
