import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PROD_ID);
  }
  return stripePromise;
};
export default initializeStripe;
