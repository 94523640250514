import React from "react";
import Modal from "react-modal";
import VipChatPlansCard from "./VipChatPlansCard";

const VipSubscriptionModal = ({
  isModalOpen,
  closeModal,
  setIsLoading,
  premiumStatus,
  successUrl,
  cancelUrl,
}) => {
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={customStyles}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="vip-subscription-modal"
      >
        <div className="mb-4">
          <p className="text-center text-2xl text-[#a44d91] font-semibold">
            Get GujjuWeds VIP
          </p>
          <p className="text-center text-xl text-[#5d5fef] font-semibold mt-2">
            Send personlized Requests to stand out from the crowd & match.
          </p>
          <p className="text-center text-xl text-gray-400 font-semibold mt-4">
            Subscribe
          </p>
        </div>

        <VipChatPlansCard
          premiumStatus={premiumStatus}
          setIsLoading={setIsLoading}
          successUrl={successUrl}
          cancelUrl={cancelUrl}
        />
      </Modal>
    </>
  );
};

VipSubscriptionModal.defalutProps = {
  isModalOpen: false,
  closeModal: () => {},
  setIsLoading: () => {},
  premiumStatus: null,
  successUrl: "",
  cancelUrl: "",
};

export default VipSubscriptionModal;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "900px",
    padding: "10px 40px 40px",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    tab: "",
    borderWidth: 2,
  },
};
