import React from "react";
import get from "lodash/get";
import { useAuth } from "../../contexts/AuthContext";
import {
  monthlyVIPCS,
  quartlyVIPCS,
  yearlyVIPCS,
} from "../../stripe/checkout-session/createCheckoutSessionForPlan";
import {
  getIsMonthlyVipBtnDisable,
  getIsQuartlyVipBtnDisable,
  getIsYearlyVipBtnDisable,
} from "../../stripe/function";
import stripeConstants from "../../stripe/stripeConstants";
import priceConstants from "../../stripe/priceConstants";
import Stars from "../../assets/icons/star-1.png";
import Heart from "../../assets/icons/heart.png";
import heartArrow from "../../assets/icons/heart-with-arrow.png";

const { STRIPE_ROLE } = stripeConstants;
const { MONTHLY_VIP_PRICE, QUARTERLY_VIP__PRICE, YEARLY_VIP_PRICE } =
  priceConstants;

const VipChatPlansCard = ({
  premiumStatus,
  setIsLoading,
  successUrl,
  cancelUrl,
}) => {
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");
  const currency = localStorage.getItem("currency");

  return (
    <>
      <div className="flex justify-center flex-wrap">
        <div className="m-2 border-2 border-spacing-1 border-gray-400 rounded-md p-10 hover:border-[#a44d91]">
          <img src={Stars} alt="image" width={32} className="mx-auto my-2" />
          <p className="text-xl text-[#5d5fef] font-semibold text-center">
            {"1 Month"}
          </p>
          <p className="text-sm text-black font-semibold text-center mb-2">
            {MONTHLY_VIP_PRICE[currency] ?? MONTHLY_VIP_PRICE.USD} / month
          </p>
          <button
            className={`btn px-6 py-2 rounded-md border-[#a44d91] ${
              getIsMonthlyVipBtnDisable(premiumStatus)
                ? "cursor-not-allowed bg-[#ECECEC] text-black"
                : "bg-[#a44d91] text-white"
            }`}
            onClick={() =>
              monthlyVIPCS(uid, setIsLoading, successUrl, cancelUrl)
            }
            disabled={getIsMonthlyVipBtnDisable(premiumStatus)}
          >
            {premiumStatus === STRIPE_ROLE.MONTHLY_VIP
              ? "Subscribed"
              : "Subscribe"}
          </button>
        </div>

        <div className="m-2 border-2 border-spacing-1 border-gray-400 rounded-md p-10 hover:border-[#a44d91]">
          <img src={Heart} alt="image" width={32} className="mx-auto my-2" />
          <p className="text-xl text-[#5d5fef] font-semibold text-center">
            {"3 Month"}
          </p>
          <p className="text-sm text-black font-semibold text-center mb-2">
            {QUARTERLY_VIP__PRICE[currency] ?? QUARTERLY_VIP__PRICE.USD} / 3
            months
          </p>
          <button
            className={`btn px-6 py-2 rounded-md border-[#a44d91] ${
              getIsQuartlyVipBtnDisable(premiumStatus)
                ? "cursor-not-allowed bg-[#ECECEC] text-black"
                : "bg-[#a44d91] text-white"
            }`}
            onClick={() =>
              quartlyVIPCS(uid, setIsLoading, successUrl, cancelUrl)
            }
            disabled={getIsQuartlyVipBtnDisable(premiumStatus)}
          >
            {premiumStatus === STRIPE_ROLE.QUARTERLY_VIP
              ? "Subscribed"
              : "Subscribe"}
          </button>
        </div>

        <div className="m-2 border-2 border-spacing-1 border-gray-400 rounded-md p-10 hover:border-[#a44d91]">
          <img
            src={heartArrow}
            alt="image"
            width={32}
            className="mx-auto my-2"
          />
          <p className="text-xl text-[#5d5fef] font-semibold text-center">
            {"12 Month"}
          </p>
          <p className="text-sm text-black font-semibold text-center mb-2">
            {YEARLY_VIP_PRICE[currency] ?? YEARLY_VIP_PRICE.USD} / 12 months
          </p>
          <button
            className={`btn px-6 py-2 rounded-md border-[#a44d91] ${
              getIsYearlyVipBtnDisable(premiumStatus)
                ? "cursor-not-allowed bg-[#ECECEC] text-black"
                : "bg-[#a44d91] text-white"
            }`}
            onClick={() =>
              yearlyVIPCS(uid, setIsLoading, successUrl, cancelUrl)
            }
            disabled={getIsYearlyVipBtnDisable(premiumStatus)}
          >
            {premiumStatus === STRIPE_ROLE.YEARLY_VIP
              ? "Subscribed"
              : "Subscribe"}
          </button>
        </div>
      </div>
    </>
  );
};

VipChatPlansCard.defaultProps = {
  premiumStatus: null,
  setIsLoading: () => {},
  successUrl: "",
  cancelUrl: "",
};

export default VipChatPlansCard;
