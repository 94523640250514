import { Slide } from "react-toastify";

const CLOUD_FUN_LOCAL_URL =
  "http://127.0.0.1:5001/theaihelper-f46d1/us-central1";
const CLOUD_FUN_PROD_URL =
  "https://us-central1-theaihelper-f46d1.cloudfunctions.net";

const DB_NAME = {
  TEST: "test",
  MESSAGED_USER: "MessagedUser",
  BLOCKED_USERS: "blockedUsers",
  DELETE_CHAT: "deleteChat",
  FCM_TOKENS: "fcmTokens",
  FILTERS: "filters",

  LOCATIONS: "locations",
  USERS: "users",
  USER_STRIPE: "users-stripe",
};

const REALTIME_DB_NAME = {
  CHAT_ALL: "chat_all",
  CHAT_PERSONAL: "chat_personal",
  PERSONAL_CHAT_INBOX: "chat_personal_inbox",
  MY_BLOCK_LIST: "myblocklist",

  NOTIFICATION: "notification",
  STATUS: "status",
  UNREAD_STATUS: "unread_status",
  USERS: "users",
};

const PATH = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",

  HOME: "/",
  PROFILE: "/profile",
  PROFILE_FORM: "/profile/form",
  CHATBOX: "/chatbox",
  SUBSCRIPTION: "/subscription",

  ADMIN: "/admin",
};

const SCREEN = {
  USER_PROFILE: "userProfile",
};

const CURRENT_YEAR = new Date().getFullYear();

const TOAST_CONTAINER_CONFIG = {
  autoClose: 2000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  transition: Slide,
};

const HOME_TAB_OBJ = {
  nearYou: "Near You",
  online: "Online",
  new: "New",
};

const HOME_TAB = [
  {
    label: "Near You",
    value: HOME_TAB_OBJ.nearYou,
  },
  {
    label: "Online",
    value: HOME_TAB_OBJ.online,
  },
  {
    label: "New",
    value: HOME_TAB_OBJ.new,
  },
];

const USERS_LIMIT = 44;
const ADMIN_USERS_LIMIT = 24;
const MAX_CONSECUTIVE_MESSAGE_LIMIT = 7;

const FREE_CHAT_LIMIT = {
  MALE: 25,
  FEMALE: 25,
};

const INITIAL_FILTER = {
  distance: 400,
  age: [18, 45],
  ageOption: [18, 45],
  heightRange: [120, 240],
  heightFt: [3, 8],
  heightInch: [0, 11],
  skin: { label: "", value: "" },
  religion: { label: "", value: "" },
  relationship: { label: "", value: "" },
  body: { label: "", value: "" },
  education: { label: "", value: "" },
  smoke: { label: "", value: "" },
  exercise: { label: "", value: "" },
};

export default {
  CLOUD_FUN_LOCAL_URL,
  CLOUD_FUN_PROD_URL,
  DB_NAME,
  REALTIME_DB_NAME,
  PATH,
  SCREEN,
  CURRENT_YEAR,
  TOAST_CONTAINER_CONFIG,
  HOME_TAB_OBJ,
  HOME_TAB,
  USERS_LIMIT,
  ADMIN_USERS_LIMIT,
  MAX_CONSECUTIVE_MESSAGE_LIMIT,
  FREE_CHAT_LIMIT,
  INITIAL_FILTER,
};
