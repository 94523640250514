import { useState, useEffect } from "react";
import { db } from "../../firebase";
import usePremiumStatus from "./usePremiumStatus";
import { useAuth } from "../../contexts/AuthContext";

const useFetchAllSubs = () => {
  const { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const { premiumStatus: premiumStatusRole } = usePremiumStatus(currentUser);

  const [isLoading, setIsLoading] = useState(false);
  const [allSubscriptions, setAllSubscriptions] = useState([]);

  const getAllSubscriptions = () => {
    setIsLoading(true);
    db.collection("users-stripe")
      .doc(uid)
      .collection("subscriptions")
      .get()
      .then((querySnapshot) => {
        const subscriptionsData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            subscriptionsData.push({
              subscriptionId: doc.id,
              data,
            });
          }
        });

        setAllSubscriptions(subscriptionsData);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllSubscriptions();
  }, [premiumStatusRole]);

  return { isLoading, allSubscriptions, premiumStatusRole };
};

export default useFetchAllSubs;
