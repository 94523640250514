import { useState, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { ref, onValue } from "firebase/database";
import { realTimeDB } from "../../firebase";
import { AuthContext } from "../../contexts/AuthContext";
import constants from "../../utils/constants";

const { REALTIME_DB_NAME } = constants;

const useFetchPersonalChatInbox = () => {
  const { currentUser } = useContext(AuthContext);
  const uid = get(currentUser, "uid", "");
  const [isLoading, setIsLoading] = useState(false);
  const [isGotResponse, setIsGotResponse] = useState(false);
  const [response, setResponse] = useState([]);
  const [unreadChatCount, setUnreadChatCount] = useState(0);

  const getUnreadChatCount = (records = []) => {
    let count = 0;
    for (let item of records) {
      if (item.unread) {
        count++;
      }
    }
    return count;
  };

  const fetchPersonalChatInbox = async () => {
    setIsLoading(true);
    setIsGotResponse(false);
    const dbRef = ref(
      realTimeDB,
      `${REALTIME_DB_NAME.PERSONAL_CHAT_INBOX}/` + uid
    );

    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach((childSnapshot) => {
        const keyName = childSnapshot.key;
        const data = childSnapshot.val();
        records.push({ key: keyName, ...data });
      });

      if (!isEmpty(records)) {
        setUnreadChatCount(getUnreadChatCount(records));
        setResponse(records);
        setIsGotResponse(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (uid) {
      fetchPersonalChatInbox();
    } else {
      setUnreadChatCount(0);
    }
  }, [uid]);

  return { isLoading, isGotResponse, response, unreadChatCount };
};

export default useFetchPersonalChatInbox;
