import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/common/Header";
import Loader from "../components/common/Loader";
import PrivateRoute from "../routes/PrivateRoute";
import { AuthProvider } from "../contexts/AuthContext";
import constants from "../utils/constants";

const PageNotFound = lazy(() => import("../pages/page-not-found"));
const Home = lazy(() => import("../pages/home"));
const Login = lazy(() => import("../pages/auth/login"));
const Signup = lazy(() => import("../pages/auth/signup"));
const ForgotPassword = lazy(() => import("../pages/auth/forgot-password"));
const Profile = lazy(() => import("../pages/profile"));
const ProfileForm = lazy(() => import("../pages/profile/form"));
const ChatBox = lazy(() => import("../pages/chat/chatbox"));
const Subscription = lazy(() => import("../pages/subscription"));
const Admin = lazy(() => import("../pages/admin"));

const { PATH } = constants;

const Routers = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <AuthProvider>
          <Header />
          <Routes>
            <Route exact path={PATH.LOGIN} element={<Login />} />
            <Route exact path={PATH.SIGNUP} element={<Signup />} />
            <Route
              exact
              path={PATH.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
            <Route path="*" element={<PageNotFound />} />

            <Route
              exact
              path={PATH.HOME}
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={PATH.PROFILE}
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={PATH.PROFILE_FORM}
              element={
                <PrivateRoute>
                  <ProfileForm />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path={PATH.CHATBOX}
              element={
                <PrivateRoute>
                  <ChatBox />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={PATH.SUBSCRIPTION}
              element={
                <PrivateRoute>
                  <Subscription />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={PATH.ADMIN}
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </Suspense>
    </>
  );
};

export default Routers;
