import React, { useEffect, useState } from "react";

const UserBouncingLoader = () => {
  const [textColor, setTextColor] = useState("white");

  useEffect(() => {
    const dotColors = ["#a44d91", "#FFFFFF", "#5d5fef"];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setTextColor(dotColors[currentIndex]);
      currentIndex = (currentIndex + 1) % dotColors.length;
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex flex-col items-center justify-center z-10">
      <div className="flex">
        <div className="h-8 w-8 bg-[#a44d91] rounded-full animate-bounce me-1 [animation-delay:0.3s]"></div>
        <div className="h-8 w-8 bg-white rounded-full animate-bounce me-1 [animation-delay:0.15s]"></div>
        <div className="h-8 w-8 bg-[#5d5fef] rounded-full animate-bounce"></div>
      </div>
      <div className="text-3xl font-semibold" style={{ color: textColor }}>
        Loading
      </div>
    </div>
  );
};

export default UserBouncingLoader;
