import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useSetStatusOffline from "../../hooks/user/useSetStatusOffline";
import constants from "../../utils/constants";

const { PATH } = constants;

const HeaderPopUp = ({
  isOpenHeaderPopUp,
  setIsOpenHeaderPopUp,
  profile,
  setIsPersonalInboxOpen,
}) => {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const email = currentUser?.email ? currentUser?.email : profile?.email;
  const { handleSetStatusOffile } = useSetStatusOffline();

  const handleProfileClick = () => {
    setIsOpenHeaderPopUp(!isOpenHeaderPopUp);
    navigate(PATH.PROFILE);
  };

  const handleSubscriptionClick = () => {
    setIsOpenHeaderPopUp(!isOpenHeaderPopUp);
    navigate(PATH.SUBSCRIPTION);
  };

  const handleLogout = async () => {
    handleSetStatusOffile();
    await logout();
    setIsPersonalInboxOpen(false);
    localStorage.clear();
    setIsOpenHeaderPopUp(!isOpenHeaderPopUp);
    navigate(PATH.LOGIN);
  };

  return (
    <div
      className={`bg-[#ffffff] text-black rounded-md rounded-t-none border border-[gray] absolute top-14 right-0 z-10 w-[75%] xl:w-[20%] lg:w-[25%] md:w-[35%] sm:w-[38%] ${
        isOpenHeaderPopUp ? "max-h-auto" : "hidden"
      }`}
    >
      <ul className="p-1">
        {email && (
          <button className="p-2 text-black w-full font-semibold border-b-2 border-black-100 cursor-default">
            {email}
          </button>
        )}

        <button
          className="p-2 text-black hover:bg-[#a44d91] hover:text-white w-full hover:rounded font-semibold border-b-2 border-black-100"
          onClick={handleProfileClick}
        >
          Profile
        </button>
        <button
          className="p-2 text-black hover:bg-[#a44d91] hover:text-white w-full hover:rounded font-semibold border-b-2 border-black-100"
          onClick={handleSubscriptionClick}
        >
          Subscription
        </button>

        <button
          className="p-2 text-black hover:bg-[#a44d91] hover:text-white w-full hover:rounded font-semibold"
          onClick={handleLogout}
        >
          Logout
        </button>
      </ul>
    </div>
  );
};

HeaderPopUp.defalutProps = {
  isOpenHeaderPopUp: false,
  setIsOpenHeaderPopUp: () => {},
  profile: null,
  setIsPersonalInboxOpen: () => {},
};

export default HeaderPopUp;
