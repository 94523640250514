import React from "react";
import Logo from "../../assets/images/logo.png";

const Loader = () => {
  return (
    <>
      <div className="loader-fill">
        <div className="loader-body">
          <span className="loader-logo mb-3 mb-md-4">
            <img loading="lazy" src={Logo} alt="Logo" width={100} />
          </span>
          <div className="loader-bar">
            <div className="loader-bar-line"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
