import constants from "../utils/constants";

const { MAX_CONSECUTIVE_MESSAGE_LIMIT, FREE_CHAT_LIMIT } = constants;

export const getChatRoom = (uid, receiverUid) => {
  const chatRoom =
    "chat_" +
    (uid < receiverUid ? uid + "_" + receiverUid : receiverUid + "_" + uid);

  return chatRoom;
};

export const getMessageLocalLimit = (personalChatResponse = [], uid) => {
  const last7ChatResponse = personalChatResponse
    ? personalChatResponse.slice(Math.max(personalChatResponse.length - 7, 1))
    : [];
  let localLimitCheck = 0;

  if (
    last7ChatResponse &&
    last7ChatResponse.length >= MAX_CONSECUTIVE_MESSAGE_LIMIT
  ) {
    last7ChatResponse.forEach((item) => {
      if (item.user._id === uid) {
        localLimitCheck += 1;
      } else {
        localLimitCheck = 0;
      }
    });
  }
  return localLimitCheck;
};

export const getIsExceedFreeChatLimit = (
  personalChatResponse = [],
  uid,
  gender
) => {
  let isLimitExceed = false;
  let chatCount = 0;
  personalChatResponse.forEach((item) => {
    if (item.user._id === uid) {
      chatCount++;
    }
  });

  if (gender === "Female") {
    isLimitExceed = chatCount < FREE_CHAT_LIMIT.FEMALE ? false : true;
  } else {
    isLimitExceed = chatCount < FREE_CHAT_LIMIT.MALE ? false : true;
  }

  return isLimitExceed;
};
