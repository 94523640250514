import get from "lodash/get";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import constants from "../../../utils/constants";

const { DB_NAME } = constants;

const useUnblockUser = (
  receiverUid,
  setIsYouBlockedReceiverUser,
  setIsLoading,
  setIsOpenModal
) => {
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");

  const handleUnBlock = async () => {
    setIsOpenModal(false);
    setIsLoading(true);

    await db
      .collection(DB_NAME.BLOCKED_USERS)
      .doc(uid)
      .update({
        [`blockedUser.${receiverUid}`]: firebase.firestore.FieldValue.delete(),
      })
      .then(() => {
        setIsYouBlockedReceiverUser(false);
        setIsLoading(false);
        toast.success("User Unblocked");
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Something went wrong. Please try again.");
      });

    await db
      .collection(DB_NAME.USERS)
      .doc(receiverUid)
      .set(
        {
          blockedCount: firebase.firestore.FieldValue.increment(-1),
        },
        { merge: true }
      );
  };

  return { handleUnBlock };
};

export default useUnblockUser;
