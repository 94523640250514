import React from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes/Routes";
import constants from "./utils/constants";

const { TOAST_CONTAINER_CONFIG } = constants;

const App = () => {
  return (
    <>
      <Router>
        <ToastContainer {...TOAST_CONTAINER_CONFIG} />
        <Routes />
      </Router>
    </>
  );
};

export default App;
